<template>
    <b-overlay :show="showLoader">
        <b-card :title="$t('reporting.issued_bills.issued_bills')">
            <div class="my-2" v-if="computedColumnDefs && paymentTypes && paymentTypes.length">
                <CustomTable :fields="computedColumnDefs" api-url="/api/client/v1/issued_bills/" >
                    <template #actions="{item}">
                        <BButton size="sm" style="min-width: 120px; padding: 5px 10px" @click="previewBill(item.id)">
                            {{ $t('general.view_bill')}}
                        </BButton>
                    </template>
                </CustomTable>
            </div>
        </b-card>
    </b-overlay>
</template>

<script>

    import {BCard, BButton, BOverlay} from 'bootstrap-vue'
    import CustomTable from '@/components/CustomTable.vue'
    import * as Sentry from '@sentry/vue'
    import {Formaters} from '@/libs/formaters'
    import {FilterFunctions} from '@/libs/filterFunctions'

    export default {
        components: {
            BOverlay,
            CustomTable,
            BCard,
            BButton
        },
        computed:{
            computedColumnDefs() {
                return  [
                    {
                        key: 'joined_bill_identifier',
                        label: this.$t('table_fields.code'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'joined_bill_identifier.w',
                        permissions: true,
                        minWidth: 100
                    },
                    {
                        key: 'timestamp',
                        label: this.$t('table_fields.cash_register_created_at'),
                        filter: true,
                        permissions: true,
                        formatter: Formaters.formatDate,
                        filterType: 'date',
                        minWidth: 150
                    },
                    {
                        key: 'total_price',
                        label: this.$t('table_fields.total_price'),
                        filter: true,
                        filterType: 'input',
                        filterKey: 'total_price.w',
                        formatter: Formaters.formatPrice,
                        filterFormatter: Formaters.formatNumberForFilter,
                        permissions: true
                    },
                    {
                        key: 'payment_types',
                        label: this.$t('table_fields.payment_type'),
                        filter: true,
                        filterType: 'select',
                        filterParams: this.paymentTypes,
                        filterParamsName: 'name',
                        filterKey: 'payment_types.payment_type_id', // po cem filtrira, default = key
                        filterFunction: FilterFunctions.filterPaymentTypes,
                        reduce: ele => ele.id,
                        formatter: Formaters.formatPaymentType,
                        permissions: true,
                        minWidth: 140
                    },
                    {
                        key: 'employee.name', // kaj kaze v tabeli
                        label: this.$t('table_fields.waiter'),
                        filter: true,
                        filterType: 'select',
                        filterParams: this.employees,
                        filterParamsName: 'name',
                        filterKey: 'employee.id',
                        reduce: ele => ele.id,
                        permissions: true,
                        minWidth: 140
                    },
                    {
                        key: 'buyer.name',
                        label: this.$t('table_fields.buyer'),
                        filter: true,
                        filterType: 'select',
                        filterParams: this.buyers,
                        filterParamsName: 'name',
                        filterKey: 'buyer.id',
                        reduce: ele => ele.id,
                        permissions: true,
                        minWidth: 100,
                        maxWidth: 200
                    },
                    {
                        key: 'actions',
                        label: this.$t('table_fields.actions'),
                        permissions: true,
                        disableSelect: true
                    }
                ]
            }
        },
        data() {
            return {
                paymentTypes: [],
                employees: [],
                buyers: [],
                showLoader: false,
                Formaters
            }
        },
        methods: {
            previewBill(id) {
                this.$router.push({name: 'preview_bill', params:{bill_id: id}})
            },
            async loadData() {
                try {
                    this.showLoader = true
                    const res1 = this.$http.get('/api/client/v1/payment_types')
                    const res2 = await this.$http.get('/api/client/v1/employees/')
                    const res3 = await this.$http.get('/api/client/v1/buyers/')

                    const responses = await Promise.all([res1, res2, res3])

                    this.paymentTypes = responses[0].data
                    this.employees = responses[1].data
                    this.buyers = responses[2].data

                    const indexPaymentTypes = this.computedColumnDefs.findIndex(ele => ele.key === 'payment_types')
                    const indexEmployees = this.computedColumnDefs.findIndex(ele => ele.key === 'employee.name')
                    const indexBuyers = this.computedColumnDefs.findIndex(ele => ele.key === 'buyer.name')

                    if (indexPaymentTypes >= 0) {
                        this.computedColumnDefs[indexPaymentTypes].filterParams = this.paymentTypes
                    }
                    if (indexEmployees >= 0) {
                        this.computedColumnDefs[indexEmployees].filterParams = this.employees
                    }
                    if (indexBuyers >= 0) {
                        this.computedColumnDefs[indexBuyers].filterParams = this.buyers
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>
export const FilterFunctions = {
    filterPaymentTypes(row, value) {
        let flag = false

        for (const paymentType of row.payment_types) {
            if (paymentType.payment_type_id === value) {
                flag = true
            }
        }

        return flag
    }
}